// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/login-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".auth-login[data-v-555c0156]{position:relative;width:100vw;height:100vh;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover}.auth-login .login-box[data-v-555c0156]{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:400px;padding:20px 30px 50px 30px;background:#fff;border-radius:5px;box-shadow:0 0 10px rgba(0,0,0,.1)}.auth-login .login-box .logo[data-v-555c0156]{display:block;text-align:center}.auth-login .login-box .logo img[data-v-555c0156]{width:120px}.auth-login .login-box .login-button[data-v-555c0156]{width:100%}.auth-login .login-box .reset-button[data-v-555c0156]{display:block;margin-top:20px;text-align:center;font-size:14px;color:#333;cursor:pointer;-webkit-transition:all .2s ease;transition:all .2s ease}.auth-login .login-box .reset-button[data-v-555c0156]:hover{color:#409eff}.auth-login .beian[data-v-555c0156]{position:absolute;bottom:20px;left:0;width:100%;text-align:center;font-size:14px;color:#fff}.auth-login .beian a[data-v-555c0156]{color:#fff;text-decoration:none}", ""]);
// Exports
module.exports = exports;
