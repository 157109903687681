<template>
    <div class="auth-login">
        <div class="login-box">
            <a class="logo">
                <img draggable="false" src="../../assets/img/logo-login.png">
            </a>
            <el-form :model="loginForm" label-position="left" label-width="60px">
                <el-form-item label="用户名">
                    <el-input v-model="loginForm.username" placeholder="请输入您的用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="loginForm.password" placeholder="请输入您的密码" type="password"></el-input>
                </el-form-item>
            </el-form>
            <el-button class="login-button" type="primary" @click="login">登录</el-button>
            <a class="reset-button">忘记密码?点击找回密码</a>
        </div>
        <p class="beian">版权所有©南京妃凡科技有限公司　ICP备案/许可证号：<a href="https://beian.miit.gov.cn">苏ICP备19002842号-1</a></p>
    </div>
</template>

<script>
import {reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus"
import {LOGIN} from "@/api/dataProvider";

export default {
    name: "AuthLogin",
    setup() {
        let $store = useStore()
        let $route = useRoute()
        let $router = useRouter()

        let state = reactive({
            loginForm: {
                username: "",
                password: "",
            }
        })

        let login = () => {
            LOGIN(
                {
                    username: state.loginForm.username,
                    password: state.loginForm.password,
                },
                function (res) {
                    if (res.data.status === 200) {
                        console.log(res.data)
                        $store.commit("setToken", res.data.data.token.accessToken);
                        $store.commit("setUserData", res.data.data.user);
                        ElMessage({
                            message: "登录成功",
                            type: "success",
                            onClose: () => {
                                $router.push({name: "Layout"})
                            }
                        });
                    } else {
                        ElMessage({
                            message: res.data.message,
                            type: "error",
                        });
                    }
                }
            )
        }
        return {
            ...toRefs(state),
            login
        }
    },
}
</script>

<style lang="scss" scoped>
.auth-login {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: url("../../assets/img/login-bg.png") no-repeat center;
    background-size: cover;

    .login-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        padding: 20px 30px 50px 30px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(#000, 0.1);

        .logo {
            display: block;
            text-align: center;

            img{
                width: 120px;
            }
        }

        .login-button {
            width: 100%;
        }

        .reset-button {
            display: block;
            margin-top: 20px;
            text-align: center;
            font-size: 14px;
            color: #333;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                color: #409EFF;
            }
        }
    }

    .beian{
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;

        a{
            color: #FFFFFF;
            text-decoration: none;
        }
    }
}
</style>
